'use client';

import { memo } from 'react';

import dynamic from 'next/dynamic';

import { useSession } from 'next-auth/react';

import { isDesktop } from 'react-device-detect';

import { useAuthStore } from '@/stores/userStore';

import {
  AUTHENTICATED_SESSION_STATUS,
  LOADING_SESSION_STATUS,
} from '@/constants/core';

import NavBarAuthenticated from '@/components/navbar/NavBarAuthenticated';
import NavigationBar from '@/components/navbar/NavigationBar';
import PublicNavBar from '@/components/navbar/PublicNavbar';

const LazySideNavbar = dynamic(() => { return import('@/components/navbar/SideBar'); }, { ssr: false });

const LazyRegistrationPopup = dynamic(() => { return import('@/components/modals/RegistrationPopup'); }, { ssr: false });

const NavigationStructure = () => {
  const {
    account,
  } = useAuthStore();

  const { status } = useSession();

  if (status === LOADING_SESSION_STATUS) {
    return null;
  }

  return (
    <>
      <div className="grid gap-0">
        {isDesktop && (
          <div className="grid col-start-1 col-end-2 row-start-2 row-end-4">
            <LazySideNavbar />
          </div>
        )}
        <div className="grid col-start-1 col-end-4 row-start-1 row-end-2 w-full fixed z-50">
          {status === AUTHENTICATED_SESSION_STATUS
            && account?.fullProfile !== undefined && (
            <LazyRegistrationPopup
              isOpen={account?.fullProfile}
              account={account}
            />
          )}
          {status === AUTHENTICATED_SESSION_STATUS ? (
            <NavBarAuthenticated />
          ) : (
            <PublicNavBar />
          )}
        </div>
      </div>
      <NavigationBar />
    </>
  );
};

export default memo(NavigationStructure);
