'use client';

import React, { useState } from 'react';

import { signIn } from 'next-auth/react';
import { FcGoogle } from 'react-icons/fc';

import { z } from 'zod';

import { AuthType, Vendors } from '@/enums/core';
import { validateEmail } from '@/features/casino/utils';
import { errors } from '@/localization';
import { userUIStore } from '@/stores/userUIStore';

import { resendEmailVerification } from '@/utils/core';
import { handleRegisterWithEmailAndPassword, handleRegisterWithGoogle } from '@/utils/firebaseOptions';

import { Button } from '@/components/ui/button';

import { useToast } from '@/components/ui/use-toast';

import { FormEmailAndPassword, FormPhoneCodeAndPassword, FormPhoneNumber } from '../components';
import { FirebaseStatus } from '../enums';
import { messages } from '../localization';
import { handleSendPhoneCode } from '../services';

interface Props {
  isMobileForm: boolean;
  setIsMobileForm: (value: boolean) => void;
  loading: boolean;
  setLoading: (value: boolean) => void;
  welcomeBonus: string | null;
  parent: string | undefined;
  clickId: string | undefined;
  btag: string | undefined;
}

const handleRegistrationStart = () => {
  if (typeof window !== 'undefined' && window.srtmCommands) {
    window.srtmCommands.push({
      event: 'track.user.registration',
      payload: { action: 'start' },
    });
  }
};

const handleRegistrationComplete = (userId: string) => {
  if (typeof window !== 'undefined' && window.srtmCommands) {
    window.srtmCommands.push({
      event: 'track.user.registration',
      payload: { action: 'complete', userId },
    });
  }
};
// eslint-disable-next-line max-lines-per-function
const FormRegistration = ({
  isMobileForm, setIsMobileForm, loading, setLoading, welcomeBonus, parent, clickId, btag,
}: Props) => {
  const { toast } = useToast();
  const [hasStartedRegistration, setHasStartedRegistration] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [opt, setOpt] = useState<any>(null);
  const [affiliateCode, setAffiliateCode] = useState<string>(parent ?? '');
  const [isAffiliateCode, setIsAffiliateCode] = useState<boolean>(false);

  const {
    toggleRegisterModal,
    toggleLoginModal,
    isCheckboxChecked,
  } = userUIStore();
  const handleFormInteraction = () => {
    if (!hasStartedRegistration) {
      handleRegistrationStart();
      setHasStartedRegistration(true);
    }
  };

  const validateAffiliateCode = async () => {
    if (!affiliateCode) return { parentType: null };
    const parentType = await fetch(`/api/referrals/?type=validate-afiliator&code=${affiliateCode}`).then((res) => { return res.json(); });
    return parentType;
  };

  const handleSendCodeToPhone = async (values: z.infer<any>): Promise<void> => {
    try {
      setLoading(true);
      const currentPhoneNumber = `+${values.phone}`;
      setPhoneNumber(currentPhoneNumber);

      const result = await handleSendPhoneCode(currentPhoneNumber);
      if (result && typeof result === 'object' && 'verificationId' in result) setOpt(result);
      else if (
        result
            && typeof result === 'object'
            && 'message' in result
            && 'className' in result
      ) toast({ title: result.message, className: result.className });
      else toast({ title: errors.tryAgain, variant: 'destructive' });
    } catch (error) {
      setLoading(false);
      toast({ title: errors.tryAgain, variant: 'destructive' });
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleRegister = async (): Promise<void> => {
    try {
      if (!welcomeBonus) {
        toast({ title: 'Debes escoger un bono de bienvenida', variant: 'destructive' });

        return;
      }

      if (!isCheckboxChecked) {
        toast({ title: messages.errorCheckbox, variant: 'destructive' });
        return;
      }
      const { parentType } = await validateAffiliateCode();

      if (parentType === 'invalid') {
        toast({ title: 'Código de afiliado inválido', variant: 'destructive' });
        return;
      }
      setLoading(true);
      handleFormInteraction();

      const credential = await handleRegisterWithGoogle();

      if (credential && typeof credential !== 'string' && credential.user) {
        const displayName = credential.user?.displayName?.split(' ');
        handleRegistrationComplete(credential.user?.uid || '');

        const options = {
          callbackUrl: '/dashboard?reg=1',
          email: credential.user?.email,
          password: credential.user?.uid,
          image: credential.user?.photoURL ?? '',
          lastName: displayName?.[1] ?? '',
          name: displayName?.[0] ?? '',
          type: AuthType.REGISTER,
          vendor: Vendors.GOOGLE,
          gift: welcomeBonus,
          parent: 0 as number | undefined,
          affiliatorId: 'NULL' as string | undefined,
          clickId: clickId || '',
          btag: btag || '',
        };
        if (parentType === 'agente') options.parent = Number(affiliateCode);
        else if (parentType === 'user') options.affiliatorId = affiliateCode;

        await signIn('credentials', options);
      } else if (
        typeof credential === 'string'
            && credential === FirebaseStatus.AUTH_POPUP_CLOSED_BY_USER
      ) {
        setLoading(false);
        toast({
          title: messages.errorPopupClosedByUser,
          variant: 'destructive',
        });
      } else {
        setLoading(false);
        toast({ title: errors.tryAgain, variant: 'destructive' });
      }
    } catch (error) {
      setLoading(false);
      toast({ title: errors.tryAgain, variant: 'destructive' });
    }
  };

  const handleCheckPhoneCodeAndRegisterWithPhone = async (
    values: z.infer<any>,
  ): Promise<void> => {
    try {
      if (!welcomeBonus) {
        toast({ title: 'Debes escoger un bono de bienvenida', variant: 'destructive' });

        return;
      }

      const { parentType } = await validateAffiliateCode();
      if (parentType === 'invalid') {
        toast({ title: 'Código de afiliado inválido', variant: 'destructive' });
        return;
      }
      setLoading(true);
      handleFormInteraction();
      const phoneCredential = await opt?.confirm(values.opt);

      if (phoneCredential) {
        handleRegistrationComplete(phoneCredential.user?.uid || '');
        const options = {
          email: phoneNumber,
          password: phoneCredential.user.uid,
          callbackUrl: '/dashboard',
          type: AuthType.REGISTER,
          vendor: Vendors.PHONE,
          gift: welcomeBonus,
          parent: 0 as number | undefined,
          affiliatorId: 'NULL' as string | undefined,
          clickId: clickId || '',
          btag: btag || '',
        };
        if (parentType === 'agente') options.parent = Number(affiliateCode);
        else if (parentType === 'user') options.affiliatorId = affiliateCode;
        await signIn('credentials', options);
      } else {
        setLoading(false);
        toast({ title: messages.errorVerifyPhoneCode, variant: 'destructive' });
      }
    } catch (error) {
      setLoading(false);
      toast({ title: errors.tryAgain, variant: 'destructive' });
    }
  };

  const handleEmailAndPassRegister = async (
    values: z.infer<any>,
  ): Promise<void> => {
    try {
      if (!welcomeBonus) {
        toast({ title: 'Debes escoger un bono de bienvenida', variant: 'destructive' });

        return;
      }

      setLoading(true);
      const { parentType } = await validateAffiliateCode();

      if (parentType === 'invalid') {
        toast({ title: 'Código de afiliado inválido', variant: 'destructive' });
        setLoading(false);
        return;
      }
      handleFormInteraction();
      if (values.email && values.password && values.ageVerification) {
        const credential = await handleRegisterWithEmailAndPassword(
          values.email,
          values.password,
        );
        if (credential && typeof credential !== 'string' && credential.user) {
          if (credential.user?.email && validateEmail(credential.user?.email)) {
            toast({ title: 'Este dominio de email no esta permitido', variant: 'destructive' });
            return;
          }
          handleRegistrationComplete(credential.user?.uid || '');
          const options = {
            email: credential.user?.email,
            password: credential.user?.uid,
            callbackUrl: '/dashboard?reg=1',
            type: AuthType.REGISTER,
            vendor: Vendors.EMAIL,
            gift: welcomeBonus,
            parent: 0 as number | undefined,
            affiliatorId: 'NULL' as string | undefined,
            clickId: clickId || '',
            btag: btag || '',
          };
          if (parentType === 'agente') options.parent = Number(affiliateCode);
          else if (parentType === 'user') options.affiliatorId = affiliateCode;
          await signIn('credentials', options);

          const isEmailSent = await resendEmailVerification();
          if (isEmailSent) {
            toast({
              title: messages.emailSent,
              className: 'bg-sivarbet-primary text-sivarbet-background',
            });
          }
        } else if (
          credential
              && typeof credential === 'string'
              && credential === FirebaseStatus.AUTH_EMAIL_EXISTS
        ) {
          setLoading(false);
          toast({
            title: messages.errorUserRegistered,
            variant: 'destructive',
          });
        } else {
          setLoading(false);
          toast({ title: errors.tryAgain, variant: 'destructive' });
        }
      } else {
        setLoading(false);
        toast({ title: errors.tryAgain, variant: 'destructive' });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('🚀 ~ handleEmailAndPassRegister ~ error:', error);
      setLoading(false);
      toast({ title: errors.tryAgain, variant: 'destructive' });
    }
  };

  return (
    <>
      {isMobileForm && !opt && (
        <FormPhoneNumber
          action={handleSendCodeToPhone}
          disabled={loading}
        />
      )}
      {isMobileForm && opt && (
        <FormPhoneCodeAndPassword
          action={handleCheckPhoneCodeAndRegisterWithPhone}
          disabled={loading}
          isRegister
        />
      )}
      {!isMobileForm && (
        <FormEmailAndPassword
          action={handleEmailAndPassRegister}
          disabled={loading}
          isRegister
        />
      )}

      <div className="h-[11px] relative tracking-[-0.01em] leading-[20px] inline-block mx-[17px] lg:hidden mb-5">
        <span>{messages.alreadyHaveAnAccount}</span>
        <span
          onClick={() => {
            toggleRegisterModal();
            toggleLoginModal();
          }}
          className="[text-decoration:underline] text-sivarbet-primary cursor-pointer"
        >
          {messages.enterHere}
        </span>
      </div>

      <div className="flex flex-col items-center justify-center gap-[10px] mx-[17px]">
        <p
          className="text-xs"
          onClick={() => {
            setIsAffiliateCode(!isAffiliateCode);
          }}
        >
          ¿Tienes un código de afiliado?
        </p>
        {isAffiliateCode && (
          <input
            type="text"
            placeholder="Código de afiliado"
            className="!w-full ![border:none] ![outline:none] !bg-sivarbet-border !self-stretch !h-7
           !shadow-[0px_1px_2px_rgba(0,_0,_0,_0.05)] !rounded-lg !flex !flex-row !justify-start
           !py-[15px] ! px-3.5 !box-border !font-text-sm-medium !font-medium !text-sm
           !text-slategray "
            onChange={(e) => {
              setAffiliateCode(e.target.value);
            }}
          />
        )}
      </div>

      <div className=" mx-auto text-center min-w-[104px] mt-5">
        {messages.orRegisterWith}
      </div>
      <div className="self-stretch flex flex-col items-start justify-start gap-[30px] text-mini text-third_text mt-5 mx-[17px]">
        <div className="self-stretch flex flex-col items-start justify-start gap-[10px]">
          <Button
            disabled={loading}
            onClick={() => {
              setIsMobileForm(!isMobileForm);
            }}
            type="button"
            className="register-button cursor-pointer [border:none] py-[10.5px] px-5 bg-sivarbet-background self-stretch rounded-lg overflow-hidden flex flex-row items-center justify-center gap-[8px]"
          >
            <span
              className={`icon icon-${!isMobileForm ? 'phone' : 'mail'} text-sivarbet-primary mt-1 mr-2`}
            />
            <div className="w-[145px] flex items-center justify-center pt-[4.5px] px-0 pb-0 box-border">
              <div className="self-stretch h-2.5 relative text-sm leading-[20px] font-medium font-text-sm-medium text-sivarbet-primary text-center flex items-center justify-center">
                {`${messages.registerWith} ${isMobileForm ? messages.yourEmail.toLocaleLowerCase() : messages.yourPhone.toLocaleLowerCase()}`}
              </div>
            </div>
          </Button>
          <Button
            disabled={loading}
            onClick={handleGoogleRegister}
            type="button"
            className="register-button cursor-pointer [border:none] py-[10.5px] px-5 bg-sivarbet-background self-stretch rounded-lg overflow-hidden flex flex-row items-center justify-center gap-[8px]"
          >

            <FcGoogle className="text-lg" />

            <div className="w-[145px] flex items-center justify-center pt-[4.5px] px-0 pb-0 box-border">
              <div className="self-stretch h-2.5 relative text-sm leading-[20px] font-medium font-text-sm-medium text-sivarbet-primary text-center flex items-center justify-center">
                {`${messages.registerWith} ${messages.google}`}
              </div>
            </div>
          </Button>
        </div>
      </div>
    </>
  );
};

export default FormRegistration;
