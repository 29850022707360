/* eslint-disable no-console */

'use client';

import React, {
  useState, useEffect,
} from 'react';

import Image from 'next/image';
import Link from 'next/link';
import { usePathname, useSearchParams } from 'next/navigation';

import { signOut, useSession } from 'next-auth/react';
import { IoCloseOutline, IoMenuSharp } from 'react-icons/io5';
import useSWR from 'swr';

import GiftBoxAnimation from '@/features/freespins/AnimateGift';
import { useAuthStore } from '@/stores/userStore';
import { userUIStore } from '@/stores/userUIStore';

import { useScrollTransparency } from '@/hooks/scrollhook';

// import { decrypt } from '@/utils/core';

import { Amounts } from '@/interfaces/core';

import DepositModal from '@/components/modals/DepositModal';
// import Notifications from '@/components/select/Notifications';
import SelectBalance from '@/components/select/SelectBalances';

import UserMenu from '@/components/select/UserMenu';
import DepositSheet from '@/components/sheet/DepositSheet';
import NotificationsSheet from '@/components/sheet/NotificationsSheet';
import SelectBalanceSheet from '@/components/sheet/SelectBalancesSheet';
import { Button } from '@/components/ui/button';

import LogoL from '@/public/img/logo/hondubetlogo.png';
import LogoS from '@/public/img/logo/hondubetsquare.png';

const fetcher = async (...args: Parameters<typeof fetch>) => {
  const res = await fetch(...args);
  return res.json();
};

const DashboardNavBar = () => {
  const pathname = usePathname();
  const [currentPathname, setCurrentPathname] = useState<string | null>(null);
  const [refreshInterval, setRefreshInterval] = useState<number>(30000);
  const [isUserIdle, setIsUserIdle] = useState<boolean>(false);
  const [amounts, setAmounts] = useState<Amounts[] | null>(null);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const isTransparent = useScrollTransparency();

  const {
    setAccount, clearStore, setBalance, balance, addAccount, setVouchers, setBonuses, account,
  } = useAuthStore();
  const { isSidebarOpen, toggleSidebar, resetSidebar } = userUIStore(); // setIsAmountsLoaded
  const { data: session } = useSession();
  const searchParams = useSearchParams();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [notificationCount, setNotificationCount] = useState(0); // State for notification count

  useEffect(() => {
    setCurrentPathname(pathname);
  }, [pathname]);

  useEffect(() => {
    resetSidebar(); // Reset the sidebar state whenever the route changes
  }, [pathname, resetSidebar, searchParams]); // Reacting to changes in path or search params

  // useEffect(() => {
  //   const fetchBadgeCount = async () => {
  //     if (session?.user?.data && session?.user?.tag) {
  //       const userId = await decrypt(session.user?.data, session.user?.tag);
  //       if ((window as any).xtremepush) {
  //         if (!isxtremepushSet) {
  //           (window as any).xtremepush('set', 'user_id', userId);
  //           setXtremepush();
  //         }
  //         await (window as any).xtremepush(
  //           'inbox',
  //           'badge',
  //           {},
  //           (result: any) => {
  //             if (result.badge !== undefined) {
  //               setNotificationCount(result.badge); // Update the badge count
  //             }
  //           },
  //         );
  //       }
  //     }
  //   };
  //   fetchBadgeCount(); // Call on component mount
  // }, [isxtremepushSet, session?.user?.data, session?.user?.tag, setXtremepush]);

  const handleHeaderBarClick = async () => {
    if (window.innerWidth > 768) {
      toggleSidebar();
    }
  };

  useEffect(() => {
    let idleTimer: string | number | NodeJS.Timeout | undefined;
    const resetIdleTimer = () => {
      clearTimeout(idleTimer);
      setIsUserIdle(false);
      idleTimer = setTimeout(() => { return setIsUserIdle(true); }, 3 * 60 * 1000); // 3 minutes
    };

    // Reset the timer when user interacts
    window.addEventListener('mousemove', resetIdleTimer);
    window.addEventListener('keypress', resetIdleTimer);

    // Set the initial timer
    resetIdleTimer();

    return () => {
      clearTimeout(idleTimer);
      window.removeEventListener('mousemove', resetIdleTimer);
      window.removeEventListener('keypress', resetIdleTimer);
    };
  }, []);

  // Adjust refreshInterval based on route and idle status
  useEffect(() => {
    if (typeof currentPathname === 'string' && currentPathname !== null) {
      if (currentPathname.startsWith('/casino/play')) {
        setRefreshInterval(10000); // Increase frequency for /play/* pages
      } else {
        setRefreshInterval(60000); // Default frequency for other pages
      }
    } else if (typeof window !== 'undefined') {
      // Handle cases where pathname is null or undefined after hydration
      setRefreshInterval(60000); // Set a default refresh interval
    } else if (searchParams && searchParams.get('deposit') === 'complete' && searchParams.get('pasarela') === 'payphone') {
      setRefreshInterval(4000);
    }
  }, [currentPathname, isUserIdle, searchParams]);

  const getBonuses = async () => {
    try {
      const response = await fetch('/api/status-bonuses');
      const data = await response.json();
      if (data.success) {
        setBonuses({
          ...data.bonuses,
          kycLevel: account?.kycLevel,
          kycStatus: account?.kycStatus,
          fullProfile: account?.fullProfile,
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching bonuses:', error);
    }
  };
  const { isLoading } = useSWR(
    `/api/balance?ref=${session?.user?.uid}`,
    fetcher,
    {
      refreshInterval: isUserIdle ? 0 : refreshInterval,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      onSuccess: async (data) => {
        try {
          // Check if the status requires sign out and clear the store
          if (data && (data.success === false || data?.data === undefined || data?.status === false)) {
            clearStore();
            signOut();
          }

          if (data && data?.data && data.balance && data.balance.length > 0) {
            setAccount(data.data);
            if (data.balance !== balance) setBalance(data.balance);
            if (data.balance[0].amount !== amounts?.[0]?.amount) setAmounts(data.balance);
          }

          if (data && data.wallets) {
            addAccount(data.wallets);
          }

          if (data?.data && data?.data.email) {
            setIsVisible(true);
          }

          if (data && data?.vouchers) {
            setVouchers(data.vouchers);
          }
          if (!currentPathname?.startsWith('/casino/play')) {
            getBonuses();
          }
        } catch (error) {
          clearStore();
          signOut();
        }
      },
    },
  );

  return (
    <header className="bg-sivarbet-background flex w-full">
      <div className="p-0 flex-1 w-full">
        <div className={`header-wrapper flex justify-between items-center p-2 px-4 max-lg:px-2 text-sivarbet-text border-none ${
          isTransparent ? 'bg-opacity-0' : 'bg-opacity-100'
        } transition-all duration-500 ease-in-out`}
        >
          <div
            className="pl-2 pr-9 flex justify-center items-center transition-all duration-1000 max-lg:hidden"
          >
            <Button
              onClick={handleHeaderBarClick}
              variant="default"
              className="bg-transparent border-none p-0 hover:bg-none"
            >
              {isSidebarOpen ? (
                <IoMenuSharp className="text-4xl text-slategray transition-all duration-1000" />
              ) : (
                <IoCloseOutline className="text-4xl text-slategray transition-all duration-1000" />
              )}
            </Button>
            <Link href="/">
              <Image src={LogoL} alt="logo" width={250} height={90} className="ml-4" priority />
            </Link>
          </div>
          <div className="max-lg:block max-lg:mx-2 hidden">
            <Link href="/">
              <Image src={LogoS} alt="logo" width={55} height={55} className="" priority />
            </Link>
          </div>
          <div className="flex items-center pl-1 w-full gap-4 max-lg:gap-4">

            {isLoading && <div className="spinner" />}
            <div className="max-lg:hidden overflow-auto">
              <SelectBalance data={amounts ?? []} />
            </div>
            <div className="lg:hidden overflow-auto">
              <SelectBalanceSheet data={amounts ?? []} />
            </div>
            <div className="max-lg:hidden">
              <DepositModal />
            </div>
            <div className="lg:hidden flex ">
              <DepositSheet />
            </div>
            <div className="max-lg:hidden">
              {isVisible && (
                <GiftBoxAnimation />
              )}
            </div>
          </div>

          <div className="flex items-center gap-4 max-lg:gap-2">
            {isVisible && (
              <div className="lg:hidden">
                <GiftBoxAnimation />
              </div>
            )}
            {/* <Notifications /> */}
            <NotificationsSheet />
            <UserMenu />
          </div>
        </div>
      </div>
      {/* <BonusModal open={open} setOpen={CloseBonusModal} /> */}
    </header>
  );
};

export default DashboardNavBar;
